import axios from 'axios';

export const createSession = async (userId, projectId) => {
    const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL
    try {
        const response = await axios.post(`${REACT_APP_API_BASE_URL}/conversation/sessions`, { userId, projectId });
        return response.data;
    } catch (error) {
        console.error('Error creating session:', error);
        throw error;
    }
};

export const getSessions = async (userId, projectId) => {
    
    const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL
    try {
        const response = await axios.get(`${REACT_APP_API_BASE_URL}/conversation/sessions`, {
            params: { userId, projectId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching sessions:', error);
        throw error;
    }
};