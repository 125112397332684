import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton, Button } from '@mui/material';
import { Visibility as VisibilityIcon } from '@mui/icons-material';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import io from 'socket.io-client';
import BuildDialog from './BuildDialog'; // Import the BuildDialog component

const IframeView = ({ src, projectId }) => {
  const { user } = useAuth0();
  const [key, setKey] = useState(0);
  const [buildUpdates, setBuildUpdates] = useState([]);
  const [buildStage, setBuildStage] = useState(0);
  const [socketConnected, setSocketConnected] = useState(false);
  const [socketError, setSocketError] = useState(null);
  const [iframeVisible, setIframeVisible] = useState(false); // State to control iframe visibility
  const [dialogBuildOpen, setDialogBuildOpen] = useState(false);
  const [dialogBuildContent, setDialogBuildContent] = useState([]);
  const [userClosedBuildDialog, setUserClosedBuildDialog] = useState(false);
  const [expandedWarnings, setExpandedWarnings] = useState([]);

  const userId = user.sub;  // Assume you get this from the user's session or context
  const REACT_APP_WS = process.env.REACT_APP_WS;
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const initialFetchDone = useRef(false); // Ref to track if initial fetch has been done

  const handleIframeError = () => {
    console.log('Error loading iframe');
  };

  const handleDialogBuildOpen = (newContent) => {
    if (!userClosedBuildDialog) {
      setDialogBuildContent((prevContent) => [...prevContent, ...newContent]);
      setDialogBuildOpen(true);
    }
  };

  const handleDialogBuildClose = () => {
    setDialogBuildOpen(false);
    setUserClosedBuildDialog(true);
  };

  const handleReopenBuildDialog = () => {
    setDialogBuildOpen(true);
    setUserClosedBuildDialog(false);
  };
  // Function to determine the color based on the error type
  const getErrorColor = (error) => {
    const lowerCaseError = error.toLowerCase();
    if (error.includes('WARN!') || error.includes('npm WARN')) {
      return 'gray';
    }
    if (lowerCaseError.includes('error:') || lowerCaseError.includes('not found') || lowerCaseError.includes('npm err')
      || lowerCaseError.includes('not be resolved') || lowerCaseError.includes('not resolve')) {
      return 'red';
    }
    return 'black';
  };

  // Function to determine the type based on the error type
  const getType = (error) => {
    const lowerCaseError = error.toLowerCase();
    if (error.includes('WARN!') || error.includes('npm WARN')) {
      return 'warning';
    }
    if (lowerCaseError.includes('error:') || lowerCaseError.includes('not found') || lowerCaseError.includes('npm err')
      || lowerCaseError.includes('not be resolved') || lowerCaseError.includes('not resolve')) {
      return 'error';
    }
    return 'notice';
  };


  // Toggle collapse for warnings
  const handleToggleWarning = (index) => {
    setExpandedWarnings((prevExpanded) =>
      prevExpanded.includes(index)
        ? prevExpanded.filter((i) => i !== index)
        : [...prevExpanded, index]
    );
  };

  // Memoized callback for handling build updates
  const handleBuildUpdate = (message) => {
    if (message.stage === 1 && message.message === 'Gathering relevant requests') {
      setDialogBuildContent([]);

    }
    setBuildStage(message.stage / 7); // Divide by 7 for progress calculation
    if ((message?.stage === 7)) {
      
      handleBuildUpdateStage7(message)
    }
    else {

      const newContent = [{ type: 'build', text: message.message, color: 'black' }];
      handleDialogBuildOpen(newContent);
      setBuildUpdates((prevUpdates) => [...prevUpdates, message.message]);

    }
  };

  // Memoized callback for handling build updates
  const handleRunTimeUpdate = (message) => {
    

      const newContent = [{ type: 'runTime', text: message.source + " " + message.message, color: 'purple' }];
      handleDialogBuildOpen(newContent);
      setBuildUpdates((prevUpdates) => [...prevUpdates, message.message]);

    
  };


  // Handling the new stage 7 build updates
  const handleBuildUpdateStage7 = (message) => {

    const { repositoryName, deploymentStatus, errors } = message;



    const newContent = [
      { type: 'status', text: `Repo: ${repositoryName}, Status: ${deploymentStatus}`, color: 'blue' },
      ...errors.map(error => ({ type: 'error', text: error, color: getErrorColor(error), repo: repositoryName }))
    ];
    handleDialogBuildOpen(newContent);
    setBuildUpdates((prevUpdates) => [...prevUpdates, message.message]);

    // Check if all repositories are in the READY state
    const allReady = dialogBuildContent
      .filter(content => content.type === 'status')
      .every(content => content.text.includes('Status: READY')) &&
      deploymentStatus === 'READY';

    if (allReady) {
      setIframeVisible(true);

    }
  };

  useEffect(() => {
    const socket = io(REACT_APP_WS);

    socket.on('connect', () => {
      setSocketConnected(true);
      console.log('Connected to socket');
      console.log('ProjectId', projectId);

      // Optionally join a session if needed
      socket.emit('joinSession', { sessionId: projectId });
    });

    socket.on('connect_error', (error) => {
      setSocketError(error.message);
      console.error('Socket connection error:', error);
    });

    socket.on('disconnect', () => {
      setSocketConnected(false);
      console.log('Disconnected from socket');
    });

    socket.on('buildUpdate', handleBuildUpdate);
    socket.on('runTime', handleRunTimeUpdate)


    // Cleanup function to disconnect socket on unmount
    return () => {
      socket.disconnect();
    };
  }, [REACT_APP_WS, projectId]);

  // Fetch initial deployment status
  useEffect(() => {
    const fetchDeploymentStatus = async () => {
      try {
        const response = await axios.get(`${REACT_APP_API_BASE_URL}/projects/idWithDetails/${projectId}`);
        const statuses = response.data.deploymentStatuses;
        const initialContent = statuses.flatMap(status => [
          { type: 'status', text: `Repo: ${status.repositoryName}, Status: ${status.deploymentStatus}`, color: 'blue' },
          ...status.errors.map(error => ({ type: getType(error), text: error, color: getErrorColor(error), repo: status.repositoryName }))
        ]);

        handleDialogBuildOpen(initialContent);

        // Check if all repositories are in the READY state
        const allReady = initialContent
          .filter(content => content.type === 'status')
          .every(content => content.text.includes('Status: READY'));

        if (allReady) {
          setIframeVisible(true);
        }
      } catch (error) {
        console.error('Error fetching deployment status:', error);
        const errorMessage = `Error fetching initial status: ${error.message}`;

        handleDialogBuildOpen([{ type: 'error', text: errorMessage, color: 'red' }]);

      }
    };

    if (!initialFetchDone.current) {
      fetchDeploymentStatus();
      initialFetchDone.current = true;
    }
  }, [REACT_APP_API_BASE_URL, projectId]);

  return (
    <Box sx={{ height: '100%', width: '100%', position: 'relative' }}>
      {iframeVisible && (
        <iframe
          src={src}
          key={key}
          onError={handleIframeError}
          style={{ border: 'none', height: '100%', width: '100%' }}
          title="Online App"
        />
      )}

      <Box mt={4} width="100%">
        <Typography variant="h6">Connection Status:</Typography>
        <Typography variant="body1">
          {socketConnected ? 'Connected' : 'Disconnected'}
        </Typography>
        {socketError && (
          <Typography variant="body1" color="error">
            {socketError}
          </Typography>
        )}
        {!dialogBuildOpen && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<VisibilityIcon />}
            onClick={handleReopenBuildDialog}
            sx={{ mt: 2 }}
          >
            Show Build Details
          </Button>
        )}
      </Box>

      <BuildDialog
        open={dialogBuildOpen}
        handleClose={handleDialogBuildClose}
        buildStage={buildStage}
        dialogBuildContent={dialogBuildContent}
        expandedWarnings={expandedWarnings}
        handleToggleWarning={handleToggleWarning}
      />
    </Box>
  );
};

IframeView.propTypes = {
  src: PropTypes.string,
  projectId: PropTypes.string.isRequired,
};

export default IframeView;
