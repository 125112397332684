import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import axios from 'axios';

const checkUrl = async (url) => {
    try {
        await axios.head(url);
        return true;
    } catch (error) {
        return false;
    }
};

const IframeWithFallback = ({ src, title, fallbackImage }) => {
    const [isValidUrl, setIsValidUrl] = useState(true);

    useEffect(() => {
        const validateUrl = async () => {
            const valid = await checkUrl(src);
            setIsValidUrl(valid);
        };

        validateUrl();
    }, [src]);

    return (
        <Box
            sx={{ cursor: 'pointer', borderRadius: 5, overflow: 'hidden', position: 'relative' }}
        >
            {isValidUrl ? (
                <iframe
                    src={src}
                    title={title}
                    style={{ width: '100%', height: '150px', border: 'none', overflow: 'hidden', pointerEvents: 'none' }}
                    scrolling="no"
                ></iframe>
            ) : (
                <img
                    src={fallbackImage}
                    alt="Default"
                    style={{ width: '50%', height: '50%', pointerEvents: 'none' }}
                />
            )}
        </Box>
    );
};

export default IframeWithFallback;
