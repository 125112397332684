import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';


const UserChecker = ({ children }) => {
    const { user, isAuthenticated } = useAuth0();

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const checkUser = async () => {
            if (isAuthenticated && user) {
                try {
                    // Check if the user exists in the database
                    
                    const response = await axios.get(`${apiBaseUrl}/users/${encodeURI(user.sub)}`);
                    
                    if (response?.data?.error) {
                        // User not found, add the user
                        var success = await axios.post(`${apiBaseUrl}/users`, {
                            userId: user.sub,
                            name: user.name,
                            email: user.email,
                            picture: user.picture,
                        });
                        
                        console.log(success)
                    }
                } catch (error) {
                    console.error('Error checking/adding user:', error);
                }
            }
        };

        checkUser();
    }, [isAuthenticated, user, apiBaseUrl]);

 

    return <>{children}</>;
};

export default UserChecker;
