import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, LinearProgress, FormControlLabel, Checkbox } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import AutoScrollList from './AutoScrollList'; // Make sure the path is correct

const BuildDialog = ({ open, handleClose, buildStage, dialogBuildContent }) => {
    const [showNotices, setShowNotices] = useState(Cookies.get('showNotices') === 'true');
    const [showWarnings, setShowWarnings] = useState(Cookies.get('showWarnings') === 'true');
    const [showErrors, setShowErrors] = useState(Cookies.get('showErrors') === 'true');

    useEffect(() => {
        Cookies.set('showNotices', showNotices);
        Cookies.set('showWarnings', showWarnings);
        Cookies.set('showErrors', showErrors);
    }, [showNotices, showWarnings, showErrors]);

    const filterMessages = (messages) => {
        return messages.filter((msg) => {
            if (msg.type === 'notice' && showNotices) return true;
            if (msg.type === 'warning' && showWarnings) return true;
            if (msg.type === 'error' && showErrors) return true;
            if (msg.type === 'status') return true;
            if (msg.type === 'build') return true;
            if (msg.type === 'runTime') return true
            return false;
        });
    };

    const filteredMessages = filterMessages(dialogBuildContent);

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                right: 0,
                height: { xs: '33%', sm: '100%' }, // Adjust height for mobile and desktop
                width: { xs: '100%', sm: '33%' }, // Adjust width for mobile and desktop
                backgroundColor: 'background.paper',
                boxShadow: 3,
                zIndex: 1300, // Ensure it stays above other elements
                overflowY: 'auto',
                display: open ? 'block' : 'none'
            }}
        >
            <Box position="sticky" top={0} zIndex={2} bgcolor="background.paper" boxShadow={1} p={2}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6">Build Details</Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box display="flex" justifyContent="space-between" mt={2}>
                    <FormControlLabel
                        control={<Checkbox checked={showNotices} onChange={() => setShowNotices(!showNotices)} />}
                        label="Notices"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={showWarnings} onChange={() => setShowWarnings(!showWarnings)} />}
                        label="Warnings"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={showErrors} onChange={() => setShowErrors(!showErrors)} />}
                        label="Errors"
                    />
                </Box>
                {buildStage > 0 && buildStage < 1 && (
                    <Box display="flex" alignItems="center" mt={2}>
                        <LinearProgress variant="determinate" value={buildStage * 100} sx={{ flexGrow: 1, mr: 2 }} />
                        <Typography variant="body2" color="textSecondary">{`${Math.round(buildStage * 100)}%`}</Typography>
                    </Box>
                )}
            </Box>
            <Box px={2} pt={2}>
                <AutoScrollList items={filteredMessages} />
            </Box>
        </Box>
    );
};

BuildDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    buildStage: PropTypes.number.isRequired,
    dialogBuildContent: PropTypes.array.isRequired,
};

export default BuildDialog;
