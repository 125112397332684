import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const TypingEffect = ({ text, speed }) => {
    const [displayedText, setDisplayedText] = useState('');

    useEffect(() => {
        setDisplayedText('');  // Reset displayed text when text changes
        let index = 0;

        const interval = setInterval(() => {
            setDisplayedText((prev) => prev + text.charAt(index));
            index++;
            if (index >= text.length) {
                clearInterval(interval);
            }
        }, speed);

        return () => clearInterval(interval);  // Clear interval on cleanup
    }, [text, speed]);

    useEffect(() => {
        if (text.length > 0) {
            setDisplayedText(text.charAt(0));
        }
    }, [text]);

    return <span>{displayedText}</span>;
};

TypingEffect.propTypes = {
    text: PropTypes.string.isRequired,
    speed: PropTypes.number.isRequired,
};

export default TypingEffect;
