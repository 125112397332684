import React, { useEffect, useState } from 'react';
import { getProjectsByUserId, deleteProjectById } from '../services/api';
import { Container, Typography, Box, Grid, Card, CardContent, CardActions, Button, CircularProgress, IconButton, Avatar } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { gray } from './LandingPage/getLPTheme';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CommentIcon from '@mui/icons-material/Comment';
import FolderIcon from '@mui/icons-material/Folder';
import axios from 'axios';
import SomeProjects from './SomeProjects';
import { Web } from '@mui/icons-material';
import IframeWithFallback from './IframeWithFallback';
import CollaboratorsBox from './CollaboratorsBox';

// Function to convert a project name to a short subdomain
const convertToSubdomain = (projectName) => {
    // Replace spaces with hyphens and convert to lowercase
    let subdomain = projectName.replace(/\s+/g, '-').toLowerCase();
    subdomain = `${subdomain}`;
    return subdomain;
};

const Projects = ({ userId, mode }) => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const data = await getProjectsByUserId(userId);
                setProjects(data);
                debugger
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchProjects();
    }, [userId]);

    const handleDelete = async (projectId) => {
        try {
            await deleteProjectById(projectId);
            setProjects((prevProjects) => prevProjects.filter((project) => project.id !== projectId));
        } catch (err) {
            setError(err);
        }
    };

    // const handleAnalyzeAndSave = async (projectId) => {
    //     try {
    //         await axios.post(`${process.env.REACT_APP_API_BASE_URL}/fileIndex/analyze-and-save`, { projectId });
    //         // Handle success (e.g., show a message or refresh the project list)
    //     } catch (err) {
    //         setError(err);
    //         // Handle error
    //     }
    // };

    if (loading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        );
    }

    if (error) {
        return (
            <Container>
                <Typography variant="h6" color="error">
                    Error: {error.message}
                </Typography>
            </Container>
        );
    }

    const backgroundColor = mode === 'dark' ? gray[900] : gray[50];
    const textColor = mode === 'dark' ? 'lime' : 'black';

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
            width="100%"
            sx={{ backgroundColor, color: textColor, fontFamily: 'monospace', textAlign: 'center', mt: 10, px: { xs: 2, sm: 3 } }}
        >
            <Container maxWidth="lg">
                <Typography variant="h4" gutterBottom>
                    Projects
                </Typography>
                <Grid container spacing={4}>
                    {projects.map((project) => (
                        <Grid item key={project.id} xs={12} sm={6} md={4}>
                            <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <CardContent
                                    sx={{ flexGrow: 1 }}
                                    onClick={(e) => {
                                        navigate(`/edit/${project.id}`);
                                        e.stopPropagation();
                                    }}
                                >
                                    <IframeWithFallback
                                        src={`https://${convertToSubdomain(project.name)}.dreamcatch.ai`}
                                        title={project.name}
                                        fallbackImage="https://content.dreamcatch.ai/templateLogos/PurpleRound512.png" // Replace with your default image path
                                    />
                                    <Typography variant="h5" component="div">
                                        {project.name}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 3,
                                            WebkitBoxOrient: 'vertical',
                                        }}
                                    >
                                        {project.description}
                                    </Typography>
                                   <CollaboratorsBox project={project}/>
                                </CardContent>
                                <CardActions>
                                    <IconButton size="small" onClick={() => { /* handle thumbs up */ }} aria-label="thumbs up">
                                        <ThumbUpIcon />
                                    </IconButton>
                                    <IconButton size="small" onClick={() => { /* handle comment */ }} aria-label="comment">
                                        <CommentIcon />
                                    </IconButton>
                                    <IconButton size="small" href={`https://${convertToSubdomain(project.name)}.dreamcatch.ai`} target="_blank">
                                        <Web />
                                    </IconButton>
                                    <IconButton size="small" onClick={() => handleDelete(project.id)} aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                    {/* <IconButton size="small" onClick={() => handleAnalyzeAndSave(project.id)} aria-label="analyze">
                                        <FolderIcon />
                                    </IconButton> */}
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                    <Grid item xs={12} sm={6} md={4}>
                        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', textAlign: 'center' }}>
                            <CardContent component={Link} to="/create-project" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <AddIcon sx={{ fontSize: 60 }} />
                                <Typography variant="h5" component="div">
                                    Create New Project
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <SomeProjects mode={mode} />
        </Box>
    );
};

export default Projects;
