import React from 'react';
import { Box, Typography, Avatar, Grid } from '@mui/material';

const CollaboratorsBox = ({ project }) => {
    return (
        <Box mt={2} p={2} sx={{ borderRadius: 2, border: '1px solid', borderColor: 'grey.300', backgroundColor: 'grey.50' }}>
            <Typography variant="h6" gutterBottom>
                Collaborators
            </Typography>
            {project.Users && project.Users.length > 0 ? (
                <Grid container spacing={2}>
                    {project.Users.map((collaborator, index) => (
                        <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                            <Box display="flex" alignItems="center">
                                <Avatar src={collaborator.picture} alt={collaborator.name} sx={{ mr: 2 }} />
                                <Typography variant="body1">{collaborator.name}</Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Typography variant="body2" color="text.secondary">
                    No collaborators found.
                </Typography>
            )}
        </Box>
    );
};

export default CollaboratorsBox;
