import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import { gray } from './LandingPage/getLPTheme';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { getProjectByProjectName } from '../services/api';
import io from 'socket.io-client';

const CreateProject = ({ mode }) => {
    const { user } = useAuth0();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [statusUpdates, setStatusUpdates] = useState([]);
    const [socketConnected, setSocketConnected] = useState(false);
    const [socketError, setSocketError] = useState(null);
    const userId = user.sub;  // Assume you get this from the user's session or context
    const REACT_APP_WS = process.env.REACT_APP_WS;
    const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [showWait, setShowWait] = useState(null)
    const navigate = useNavigate();

    // Initialize socket connection
    useEffect(() => {
        
        const socket = io(REACT_APP_WS);

        socket.on('connect', () => {
            
            setSocketConnected(true);
            console.log('Connected to socket');
            // Optionally join a session if needed
            socket.emit('joinSession', { sessionId: userId });
        });

        socket.on('connect_error', (error) => {
            setSocketError(error.message);
            console.error('Socket connection error:', error);
        });

        socket.on('disconnect', () => {
            setSocketConnected(false);
            console.log('Disconnected from socket');
        });

        socket.on('statusUpdate', (message) => {
            setStatusUpdates((prevUpdates) => [...prevUpdates, message]);
        });

        return () => {
            socket.disconnect();
        };
    }, [REACT_APP_WS, userId]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const projectData = { name, description, userId, sessionId: userId }; // Use userId as sessionId for simplicity

        try {
            var check = true;

            if (!(projectData.name === "" || !projectData?.name ||
                projectData?.name?.toLowerCase().includes("cubicsearch") ||
                projectData?.name?.toLowerCase().includes("dreamcatch") ||
                projectData?.name?.length > 45
            )) {
                const pNameCheck = await getProjectByProjectName(projectData.name);
                if (pNameCheck?.exists === false) {
                    check = false;
                }
            }

            if (check === false) {
                setShowWait(true);
                const response = await axios.post(`${REACT_APP_API_BASE_URL}/projects`, projectData);
                setShowWait(false);
                console.log('Project created:', response.data);
                navigate('/'); // Redirect to the home page
            } else {
                alert("That name already is taken. Please try another!");
            }
        } catch (error) {
            console.error('Error creating project:', error);
            setShowWait(false);
            alert(`There was a problem ${error}`);
        }
    };

    const backgroundColor = mode === 'dark' ? gray[900] : gray[50];
    const textColor = mode === 'dark' ? 'lime' : 'black';

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
            minWidth="100vw"
            sx={{ backgroundColor, color: textColor, fontFamily: 'monospace', textAlign: 'center' }}
        >
            {showWait ? (
                <>
                    <CircularProgress />
                    <Box mt={4} width="100%">
                        <Typography variant="h6">Status Updates:</Typography>
                        {statusUpdates.map((status, index) => (
                            <Typography key={index} variant="body1">{status}</Typography>
                        ))}
                    </Box>
                </>
            ) : (
                <Container maxWidth="sm">
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        mt={10}
                        p={2}
                        boxShadow={3}
                        borderRadius={2}
                    >
                        <Typography variant="h4" gutterBottom>
                            Create New Project
                        </Typography>
                        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                            <TextField
                                label="Project Name"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <TextField
                                label="Description"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                multiline
                                rows={4}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                style={{ marginTop: '1em' }}
                            >
                                Create Project
                            </Button>
                        </form>
                        <Box mt={4} width="100%">
                            <Typography variant="h6">Connection Status:</Typography>
                            <Typography variant="body1">
                                {socketConnected ? 'Connected' : 'Disconnected'}
                            </Typography>
                            {socketError && (
                                <Typography variant="body1" color="error">
                                    {socketError}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Container>
            )}
        </Box>
    );
};

export default CreateProject;
