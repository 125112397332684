import React, { useEffect, useState } from 'react';
import { Container, Box } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ErrorBoundary from './ErrorBoundary';

import LandingPage from './components/LandingPage/LandingPage';
import NoProjects from './components/NoProjects';
import AppAppBar from './components/LandingPage/components/AppAppBar';
import SplitView from './components/SplitView';
import CreateProject from './components/CreateProject';
import Projects from './components/Projects';
import UserInfoForm from './components/UserInfoForm'; // Import UserInfoForm component

function App() {
  const { isLoading, error, user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [hasProjects, setHasProjects] = useState(false);
  const [userInfoComplete, setUserInfoComplete] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [mode, setMode] = useState('light');

  const theme = createTheme({
    palette: {
      mode,
    },
  });

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  useEffect(() => {
    const checkUserInfo = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          const response = await axios.get(`${apiBaseUrl}/users/${user.sub}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          const { name, email, phone } = response.data;

          setUserInfoComplete(!!name && !!email && !!phone);
        } catch (error) {
          console.error('Error fetching user info:', error);
        }
      }
    };

    const checkProjects = async () => {
      if (isAuthenticated) {
        try {
          const response = await axios.get(`${apiBaseUrl}/projects/${user.sub}`);
          setHasProjects(response.data.length > 0);
        } catch (error) {
          console.error('Error fetching projects:', error);
        }
      }
    };

    if (isAuthenticated) {
      checkUserInfo();
      checkProjects();
    }
  }, [isAuthenticated, user, apiBaseUrl, getAccessTokenSilently]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Oops... {error.message}</div>;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Routes>
            {isAuthenticated ? (
              <>
                {!userInfoComplete ? (
                  <Route path="/" element={<UserInfoForm onComplete={() => setUserInfoComplete(true)} />} />
                ) : (
                  <>
                    <Route path="/" element={hasProjects ? <Projects mode={mode} userId={user?.sub} /> : <NoProjects mode={mode} />} />
                    <Route path="/create-project" element={<CreateProject mode={mode} />} />
                    <Route path="/edit/:projectId" element={
                      <ErrorBoundary><SplitView userId={user?.sub} screenMode={mode} /></ErrorBoundary>} />
                  </>
                )}
              </>
            ) : (
              <Route path="/" element={<LandingPage />} />
            )}
            <Route path="*" element={<LandingPage />} /> {/* Fallback route */}
          </Routes>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App;
