import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { IconButton, Menu, MenuItem, Avatar, Modal, Box, Typography } from '@mui/material';
import { Logout } from '@mui/icons-material';
import axios from 'axios';
import UserInfoForm from '../../components/UserInfoForm';

const Profile = () => {
    const { user, isAuthenticated, logout, getAccessTokenSilently } = useAuth0();
    const [anchorEl, setAnchorEl] = useState(null);
    const [userInfo, setUserInfo] = useState({ name: '', picture: '' });
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const token = await getAccessTokenSilently();
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/${user.sub}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setUserInfo({
                    name: response.data.name || '',
                    picture: response.data.picture || ''
                });
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };

        if (isAuthenticated) {
            fetchUserInfo();
        }
    }, [isAuthenticated, user, getAccessTokenSilently]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        logout({ returnTo: window.location.origin });
    };

    const handleProfileOpen = () => {
        setOpenModal(true);
        handleClose();
    };

    const handleProfileClose = () => {
        setOpenModal(false);
    };

    return (
        isAuthenticated && (
            <Box display="flex" alignItems="center">
                <IconButton onClick={handleClick}>
                    <Avatar alt={userInfo.name} src={userInfo.picture} sx={{ width: 30, height: 30 }} />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleProfileOpen}>
                        Profile
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                        <Logout fontSize="small" />
                        Logout
                    </MenuItem>
                </Menu>
                <Modal
                    open={openModal}
                    onClose={handleProfileClose}
                >
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                        <Typography variant="h6" component="h2" gutterBottom>
                            Edit Profile
                        </Typography>
                        <UserInfoForm onComplete={handleProfileClose} />
                    </Box>
                </Modal>
            </Box>
        )
    );
};

export default Profile;
