import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Drawer, List, ListItem, ListItemText, Button, Fab, useMediaQuery, Typography, Link, CircularProgress } from '@mui/material';
import { Resizable } from 're-resizable';
import Chat from './Chat';
import IframeView from './IframeView';
import { createSession, getSessions } from './sessionService';
import { format } from 'date-fns';
import { ChatBubble } from '@mui/icons-material';
import axios from 'axios';
import 'react-resizable/css/styles.css'; // Import default styles

const convertToSubdomain = (projectName) => {
    let subdomain = projectName.replace(/\s+/g, '-').toLowerCase();
    return `${subdomain}`;
};

export const getProject = async (projectId) => {
    const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    try {
        const response = await axios.get(`${REACT_APP_API_BASE_URL}/projects/id/${projectId}`, {
            params: { projectId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching project:', error);
        throw error;
    }
};

const SplitView = ({ userId, screenMode }) => {
    const { projectId } = useParams();
    const [sessionId, setSessionId] = useState(null);
    const [sessions, setSessions] = useState([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [project, setProject] = useState(null);
    const [windowURL, setWindowURL] = useState(null);

    useEffect(() => {
        const fetchSessions = async () => {
            try {
                const sessions = await getSessions(userId, projectId);
                sessions.sort((a, b) => new Date(b.lastMessageCreatedAt) - new Date(a.lastMessageCreatedAt));
                if (sessions.length === 0) {
                    handleCreateNewSession();
                }
                setSessions(sessions);
            } catch (error) {
                console.error('Error fetching sessions:', error);
            }
        };

        fetchSessions();
    }, [userId, projectId, drawerOpen]);

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const tempProject = await getProject(projectId);
                setProject(tempProject);
                setWindowURL(`https://${convertToSubdomain(tempProject.name)}.dreamcatch.ai`);
            } catch (error) {
                console.error('Error fetching project:', error);
            }
        };

        if (!project) {
            fetchProject();
        }
    }, [projectId, project]);

    const handleCreateNewSession = async () => {
        try {
            const newSession = await createSession(userId, projectId);
            setSessionId(newSession.sessionId);
            setSessions([...sessions, newSession]);
            setDrawerOpen(false);
        } catch (error) {
            console.error('Error creating new session:', error);
        }
    };

    const handleSessionSelect = (sessionId) => {
        setSessionId(sessionId);
        setDrawerOpen(false);
    };

    useEffect(() => {
        if (!sessionId && sessions.length > 0) {
            setSessionId(sessions[0].sessionId);
        }
    }, [sessions, sessionId]);

    const formatDate = (date) => {
        if (date) {
            try {
                return format(new Date(date), 'Pp');
            } catch {
                return 'Invalid date';
            }
        } else {
            return 'Not Started';
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', height: '75vh', mt: 15, width: '100vw', paddingRight: 2, paddingLeft: 2 }}>
            <Drawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                <List>
                    {sessions.map((session) => (
                        <ListItem button key={session.sessionId} onClick={() => handleSessionSelect(session.sessionId)}>
                            <ListItemText
                                primary={session?.firstMessageContent?.substring(0, 30) || 'No messages'}
                                secondary={`Started: ${formatDate(session?.firstMessageCreatedAt)} | Messages: ${session.messageCount}`}
                            />
                        </ListItem>
                    ))}
                    <ListItem onClick={handleCreateNewSession}>
                        <Button variant='outlined'>Start New Session</Button>
                    </ListItem>
                </List>
            </Drawer>
            <Button onClick={() => setDrawerOpen(true)}><ChatBubble /></Button>
            <Resizable
                defaultSize={{
                    width: isSmallScreen ? window.screen.availWidth : window.screen.availWidth/2,
                    height: '100%',
                }}
              
                style={{ border: '1px solid #ddd', overflow: 'hidden' }}
            >
                <div className="custom-handle-wrapper" style={{ height: '100%' }}>
                    {sessionId && <Chat sessionId={sessionId} userId={userId} screenMode={screenMode} />}
                </div>
            </Resizable>
            {project && (
                <Box sx={{ flexGrow: 1, height: '100%', mt: 2, textAlign: 'center' }}>
                    <Typography variant="h5">{project?.name}</Typography>
                    <Link href={windowURL} target="_blank" rel="noopener" sx={{ display: 'block', mb: 2 }}>
                        {windowURL}
                    </Link>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        Your project will appear here if it is ready.
                    </Typography>
                    <Box sx={{ position: 'relative', width: '100%', height: 'calc(85% - 64px)' }}>
                        <IframeView src={windowURL} projectId={projectId} />
                    </Box>
                </Box>
            ) || <CircularProgress />}
            {isSmallScreen && (
                <Fab color="primary" aria-label="open drawer" onClick={() => setDrawerOpen(true)} sx={{ position: 'fixed', bottom: 16, right: 16 }}>
                    <ChatBubble />
                </Fab>
            )}
        </Box>
    );
};

export default SplitView;
