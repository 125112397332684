import React, { useEffect, useRef } from 'react';
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const removeEscapeSequences = (text) => {
    return text.replace(/\x1b\[[0-9;]*m/g, '');
};

const renderTextPart = (part, index) => {
    // Detect if the part is a code block by checking for the ``` at the start and end
    if (part.startsWith('```') && part.endsWith('```')) {
        const code = part.slice(3, -3).trim();
        return (
            <SyntaxHighlighter
                key={index}
                language="javascript"
                style={materialDark}
            >
                {code}
            </SyntaxHighlighter>
        );
    }
    return (
        <Typography key={index} component="span">
            {part}
        </Typography>
    );
};

const formatMessage = (message) => {
    // Split text into parts by code blocks, preserving code blocks
    const parts = message.split(/(```[\s\S]*?```)/g);
    return parts.map((part, index) => {
        // Remove escape sequences from each part
        const cleanedPart = removeEscapeSequences(part).trim();
        return renderTextPart(cleanedPart, index);
    });
};

const TextRenderer = ({ text, repo, color }) => {
    const formattedText = formatMessage(text ||'');
    return (
        <ListItemText
            primary={<div>{formattedText}</div>}
            secondary={repo}
            sx={{ color }}
        />
    );
};

TextRenderer.propTypes = {
    text: PropTypes.string.isRequired,
    repo: PropTypes.string,
    color: PropTypes.string,
};

const AutoScrollList = ({ items }) => {
    const listRef = useRef(null);
    const messagesEndRef = useRef(null);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [items]);

    return (
        <List ref={listRef} sx={{ maxHeight: { xs: 100, sm: 'calc(100% - 48px)' }, overflow: 'auto' }}>
            {items.map((item, index) => (
                <ListItem key={index}>
                    <TextRenderer text={item.text} repo={item.repo} color={item.color} />
                </ListItem>
            ))}
            <div ref={messagesEndRef} />
        </List>
    );
};

AutoScrollList.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            repo: PropTypes.string,
            color: PropTypes.string,
        })
    ).isRequired,
};

export default AutoScrollList;
