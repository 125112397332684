import axios from 'axios';
const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL
// Function to create a project
export const createProject = async (projectData) => {
    try {
        const response = await axios.post(`${REACT_APP_API_BASE_URL}/projects`, projectData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Function to get projects by user ID
export const getProjectsByUserId = async (userId) => {
    try {
        const response = await axios.get(`${REACT_APP_API_BASE_URL}/projects/${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteProjectById = async (projectId) => {
    const response = await axios.delete(`${REACT_APP_API_BASE_URL}/projects/${projectId}`);
    return response.data;
};

export const getProjectByProjectName = async (projectName) => {
    const response = await axios.get(`${REACT_APP_API_BASE_URL}/projects/projectName/${projectName}`);
    return response.data;
};