import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Avatar } from '@mui/material';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const UserInfoForm = ({ onComplete }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    picture: ''
  });
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState('');

  useEffect(() => {
    const fetchUserInfo = async () => {
      const token = await getAccessTokenSilently();
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/${user.sub}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setFormData({
        name: response.data.name || '',
        email: response.data.email || '',
        phone: response.data.phone || '',
        picture: response.data.picture || ''
      });
      setPreview(response.data.picture || '');
    };

    fetchUserInfo();
  }, [user, getAccessTokenSilently]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);

      // Upload the file to S3 and get the URL
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('key', `user-profile-pictures/${user.sub}-${selectedFile.name}`);
      formData.append('contentType', selectedFile.type);

      const token = await getAccessTokenSilently();
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/s3/upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      // Set the URL in the formData
      setFormData({
        ...formData,
        picture: response.data.Location
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = await getAccessTokenSilently();
    await axios.put(`${process.env.REACT_APP_API_BASE_URL}/users/${user.sub}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    onComplete();
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 15 }}>
      <Typography variant="h6" gutterBottom>
        Complete Your Profile
      </Typography>
      <TextField
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Phone Number"
        name="phone"
        value={formData.phone}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Profile Picture URL"
        name="picture"
        value={formData.picture}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        style={{ marginTop: '16px' }}
      />
      {preview && (
        <Box mt={2} display="flex" justifyContent="center">
          <Avatar src={preview} sx={{ width: 100, height: 100 }} />
        </Box>
      )}
      <Button type="submit" variant="contained" sx={{ mt: 2 }}>
        Save
      </Button>
    </Box>
  );
};

export default UserInfoForm;
