import React from 'react';
import './CodeBackground.css';

const CodeBackground = () => {
    return (
        <div className="code-background">
            <div className="code-line">const user = {`{`}</div>
            <div className="code-line">  id: '12345',</div>
            <div className="code-line">  name: 'John Doe',</div>
            <div className="code-line">  email: 'johndoe@example.com',</div>
            <div className="code-line">{`}`};</div>
            <div className="code-line">FOO BAR</div>
            <div className="code-line">  HELLO WORLD </div>
            <div className="code-line">  import React from'react'</div>
            <div className="code-line">  localhost:3000</div>
            <div className="code-line">{`}`};</div>
        </div>
    );
};

export default CodeBackground;
