import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <>   <Button
            onClick={() => loginWithRedirect()}
            color="primary"
            variant="text"
            size="small"
            component="a"
            // href="/material-ui/getting-started/templates/sign-in/"
            target="_blank"
        >
            Sign in
        </Button>
        
            <Button
                onClick={() => loginWithRedirect({
                    screen_hint: 'signup',
                })}
                color="primary"
                variant="contained"
                size="small"
                component="a"
                // href="/material-ui/getting-started/templates/sign-in/"
                target="_blank"
            >
                Sign up
            </Button>
        
        </>
       
    
    );
};

export default LoginButton;
