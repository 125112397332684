import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Box, TextField, Button, Typography, CircularProgress, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, List, ListItem, ListItemText, ListItemIcon, FormControl } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import io from 'socket.io-client';

const Chat = ({ sessionId, userId, screenMode }) => {
    const [messages, setMessages] = useState([]);
    const [typing, setTyping] = useState(false);
    const [otherUserTyping, setOtherUserTyping] = useState(false);
    const [input, setInput] = useState('');
    const [mode, setMode] = useState('group');
    const [isLoading, setIsLoading] = useState(false);
    const [socketInitialized, setSocketInitialized] = useState(false);
    const [hasOtherMembers, setHasOtherMembers] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedMessages, setSelectedMessages] = useState([]);
    const [messagesWithPrompt, setMessagesWithPrompt] = useState([]);
    const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const REACT_APP_WS = process.env.REACT_APP_WS;

    const socket = useRef(null);
    const messagesEndRef = useRef(null);
    const typingTimeoutRef = useRef(null);

    useEffect(() => {
        socket.current = io(REACT_APP_WS, {
            transports: ['websocket', 'polling']
        });

        socket.current.emit('joinSession', { sessionId });

        socket.current.on('message', async (message) => {
            const user = await axios.get(`${REACT_APP_API_BASE_URL}/users/${message.userId}`);
            message.User = user.data;
            setMessages((prevMessages) => [...prevMessages, message]);
        });

        setSocketInitialized(true);

        return () => {
            socket.current.disconnect();
        };
    }, [sessionId, REACT_APP_WS]);

    useEffect(() => {
        if (socketInitialized) {
            socket.current.on('typing', () => {
                setOtherUserTyping(true);
            });

            socket.current.on('stop typing', () => {
                setOtherUserTyping(false);
            });

            return () => {
                socket.current.off('typing');
                socket.current.off('stop typing');
            };
        }
    }, [socketInitialized]);

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const response = await axios.get(`${REACT_APP_API_BASE_URL}/conversation/get-messages/${sessionId}`);
                setMessages(response.data);

                const otherMembers = response.data.some(msg => (msg.userId !== userId && msg.userId !== 'AI'));
                setHasOtherMembers(otherMembers);

                if (!otherMembers) {
                    setMode('feedback');
                }
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };

        fetchMessages();
    }, [sessionId, REACT_APP_API_BASE_URL, userId]);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handleInputChange = (e) => {
        setInput(e.target.value);

        if (!typing) {
            setTyping(true);
            socket.current.emit('typing', { userId, sessionId });
        }
        clearTimeout(typingTimeoutRef.current);
        typingTimeoutRef.current = setTimeout(() => {
            setTyping(false);
            socket.current.emit('stop typing', { userId, sessionId });
        }, 3000);
    };

    const handleSendMessage = () => {
        if (input.trim() === '') return;

        const newMessage = { sessionId, role: 'user', content: input, userId, mode, updatedAt: new Date().toISOString() };
        setMessagesWithPrompt([...messages, newMessage]);
        if (mode !== 'group') {
            setDialogOpen(true);
        } else {
            handleDialogSubmit();
        }
    };

    const renderMessageContent = (content) => {
        const parts = content.split(/(```[\s\S]*?```)/g);
        return parts.map((part, index) => {
            if (part.startsWith('```') && part.endsWith('```')) {
                const code = part.slice(3, -3).trim();
                return (
                    <SyntaxHighlighter key={index} language="javascript" style={materialDark}>
                        {code}
                    </SyntaxHighlighter>
                );
            }
            return (
                <Typography key={index} variant="body1" component="span" sx={{ whiteSpace: 'pre-line' }}>
                    {part}
                </Typography>
            );
        });
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleCheckboxChange = (messageId) => {
        setSelectedMessages((prevSelected) =>
            prevSelected.includes(messageId)
                ? prevSelected.filter((id) => id !== messageId)
                : [...prevSelected, messageId]
        );
    };

    const handleDialogSubmit = async () => {
        setDialogOpen(false);
        setIsLoading(true);

        const newMessage = { sessionId, role: 'user', content: input, userId, mode };
        setInput('');
        setTyping(false);
        socket.current.emit('stop typing', { sessionId, userId });

        try {
            socket.current.emit('message', newMessage);
            const response = await axios.post(`${REACT_APP_API_BASE_URL}/conversation/chat`, {
                sessionId,
                prompt: input,
                userId,
                mode,
                relevantMessageIds: selectedMessages
            });

            if (response.data.response !== "") {
                const assistantMessage = { role: 'assistant', content: response.data.response || "Check Response", sessionId, userId: 'AI', mode };
                socket.current.emit('message', assistantMessage);
            }
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setIsLoading(false);
        }
    };

    var theYellow = 'lightyellow';
    if (screenMode === 'dark') {
        theYellow = 'darkgreen';
    }

    var theBlue = 'lightblue';
    if (screenMode === 'dark') {
        theBlue = 'darkblue';
    }

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', padding: 2 }}>
            <Box sx={{ flexGrow: 1, overflowY: 'auto', marginBottom: 2 }}>
                {messages.map((msg, index) => {
                    var width = '50%';
                    if (msg?.content?.includes("```")) {
                        width = '100%';
                    } else if (msg.role !== 'user') {
                        width = '80%';
                    }

                    return (
                        <Box
                            key={`${msg.id}-${index}`}
                            sx={{
                                backgroundColor: msg.role === 'user' ? theBlue : theYellow,
                                borderRadius: 2,
                                padding: 1,
                                marginBottom: 1,
                                textAlign: msg.role === 'user' ? 'right' : 'left',
                                alignSelf: msg.role === 'user' ? 'flex-end' : 'flex-start',
                                maxWidth: width,
                                wordWrap: 'break-word',
                                marginLeft: msg.role === 'user' ? 'auto' : '0',
                            }}
                        >
                            <Typography variant="body2" color="textSecondary">
                                {msg.role === 'user' ? `${msg?.User?.name}` : 'AI'} to {msg.mode}
                            </Typography>
                            {renderMessageContent(msg.content)}
                        </Box>
                    );
                })}
                {otherUserTyping && <Typography>... someone is responding ...</Typography>}
                {(isLoading || otherUserTyping) && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
                        <CircularProgress />
                    </Box>
                )}
                <div ref={messagesEndRef} />
            </Box>
            <TextField
                variant="outlined"
                placeholder="Type a message..."
                value={input}
                onChange={handleInputChange}
                onKeyUp={(e) => {
                    if (e.key === 'Enter') handleSendMessage();
                }}
                fullWidth
                multiline
            />
            <Button variant="contained" sx={{ marginTop: 1 }} onClick={handleSendMessage}>
                Send
            </Button>
            <FormControl component="fieldset">
                <Select
                    aria-label="audience"
                    name="audience"
                    value={mode}
                    onChange={(e) => setMode(e.target.value)}
                    fullWidth
                >
                    <MenuItem value="group">{hasOtherMembers ? "Other Chat Members" : "Note to Session (no other members present)"}</MenuItem>
                    <MenuItem value="feedback">AI Bot Advisor for Feedback</MenuItem>
                    <MenuItem value="creation">AI Code Creator to Execute the Creation of Code</MenuItem>
                </Select>
            </FormControl>
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Select Relevant Messages</DialogTitle>
                <DialogContent>
                    <List>
                        {messagesWithPrompt.slice().reverse().map((msg, index) => (
                            <ListItem key={`${msg.id}-${index}`} button onClick={() => handleCheckboxChange(msg.id)}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={selectedMessages.includes(msg.id)}
                                        tabIndex={-1}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={renderMessageContent(msg.content)} secondary={new Date(msg.updatedAt).toLocaleString()} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDialogSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Chat;
