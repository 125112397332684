import React from 'react';
import { Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import TypingEffect from './TypingEffect'; // Ensure this path is correct
import CodeBackground from './CodeBackground'; // Ensure this path is correct
import CssBaseline from '@mui/material/CssBaseline';
import { gray } from './LandingPage/getLPTheme'; // Ensure this path is correct
const NoProjects = ({ mode }) => {
    const navigate = useNavigate();

    const handleCreateProject = () => {
        navigate('/create-project');
    };

    const backgroundColor = mode === 'dark' ? gray[900] : gray[50];
    const textColor = mode === 'dark' ? 'lime' : 'black';

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
            sx={{ backgroundColor, color: textColor, fontFamily: 'monospace', textAlign: 'center' }}
        >
            <Box
                sx={{
                    width: '100%',
                    margin: '0 auto',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    fontSize: '1.5rem',
                }}
            >
                <TypingEffect text="No Projects... What shall we make today?" speed={40} />
          
              
            </Box>
            <Button
                variant="contained"
                onClick={handleCreateProject}
                sx={{ mt: 2, backgroundColor: 'lime', color: 'black' }}
            >
                Create Project
            </Button>
         
            <Box sx={{marginTop: 15, maxWidth: {sm: '50%', xs: '80%'}}}>

                <CssBaseline />
                <CodeBackground />
            </Box>
        </Box>
    );
};

NoProjects.propTypes = {
    mode: PropTypes.oneOf(['dark', 'light']).isRequired,
};

export default NoProjects;
